import axios from 'axios';

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
        timeout: 60000
    }
}
let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN + "/api/v1"
        Vue.prototype.$DOMAIN_ADMIN = process.env.VUE_APP_DOMAIN_ADMIN + "/v1"

        Vue.prototype.$AUTH_REFRESHTOKEN = "/auth/refreshToken" // 토큰 갱신

        Vue.prototype.$PAYMENT_SMS = "/payment/sms"
        Vue.prototype.$PAYMENT_SMS_INPUT = "/payment/sms/input"
        Vue.prototype.$CARD_CODE = "/card/code"

        Vue.prototype.$PAYMENT_SMS_RENT = "/monthly/sms/payment"
        Vue.prototype.$PAYMENT_HANDWRITTEN_INSTALLMENT = "/payment/handwritten/installment"


        Vue.prototype.$post = function (domain, callUrl, caller, postData, success, fail) {
            console.log('test url' + callUrl)

            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.post(domain + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$put = function (domain, callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(domain + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$delete = function (domain, callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(domain + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$get = function (domain, callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(domain + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$niceAuth = function (success, fail) {
            axios.post("https://nice.reappay.net/check-pay-plus/request", null, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                timeout: 60000
            }).then((response) => {
                if (response.data.success) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                console.log(e)
                fail(e.response)
            })
        }
    }
})
