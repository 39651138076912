<template>
    <div class="overlay" @click.self="$emit('close')">
        <div class="select-list" v-show="installmentList ? installmentList.length > 0 : false" style="height: 60%;">
            <h5 class="pt-0">할부</h5>
            <ul style="overflow: auto; height: calc(100% - 60px)">
                <li v-for="(installment, idx) in installmentList" :key="idx" :class="{ 'li_padding' : installmentLimit >= Number(installment.installment)}">
                    <a role="button" @click="selectInstallment(installment)" v-if="installmentLimit >= Number(installment.installment)">
                        <span>{{ installment.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "SMSPaySelect",

    props: ['installmentList'],
    data() {
        return {
            installmentLimit: 12,
        }
    },
    mounted() {
        this.getInstallmentInfo()
    },
    methods: {
        getInstallmentInfo() {
            this.$get(this.$PAYMENT_HANDWRITTEN_INSTALLMENT, 'get_installment_info', (result) => {
                console.log(result, 'installment')
                this.installmentLimit = result.data.installmentLimit
            }, (result) => {
                this.httpError(result)
            })
        },
        selectInstallment(installment) {
            this.$emit('selectedInstallment', installment)
        },
    },
}
</script>

<style scoped>
.select-list > ul > li {
    padding-bottom: 0;
}
.li_padding {
    padding-bottom: 30px !important;
}
</style>
